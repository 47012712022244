import React from 'react';
import { PowerEditorBase, PowerEditorBaseProps } from '../../toolympus/components/PowerDoc';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { useNestedDocumentsEditorPlugin } from './useNestedDocumentsEditorPlugin';
import { CustomizedColumnsPlugin } from './EditorCustomizations/ColumnsPlugin';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';

interface Props extends PowerEditorBaseProps {
  
}

export const SpellbookEditor = (props: Props) => {
  const mediafiles = useMediaFilesPlugin();
  const nestedDocuments = useNestedDocumentsEditorPlugin();
  return (
    <PowerEditorBase
      content={props.content}
      update={props.update}
      viewMode={props.viewMode}
      plugins={[
        mediafiles,
        CustomizedColumnsPlugin,
        nestedDocuments,
        TablesPlugin,
      ]}
      />
  );
}
