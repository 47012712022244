import { useState } from "react"
import { Project } from "./useProjectsList";

export const useEditProjectAttribute = (updateProject: (projectId: string, changes: Partial<Project>) => Promise<Project>) => {
  const [editing, setEditing] = useState<{ projectId: string, field: string, value: string, isSaving?: boolean } | null>(null);


  const complete = () => {
    if(editing) {
      const projectId = editing.projectId;
      const changes = { [editing.field]: editing.value };
      setEditing(x => x ? { ...x, isSaving: true } : x);
      return updateProject(projectId, changes)
        .then(x => setEditing(null))
        .catch(e => {
          setEditing(null);
          throw e;
        });
    }
  }

  return {
    cancel: () => setEditing(null),
    complete,
    editing,
    startEditing: (projectId: string, field: string, value: string) => {
      setEditing({ projectId, field, value });
    },
    update: (v: string) => {
      setEditing(x => x ? ({ ...x, value: v }) : x);
    },
  }
}

export type EditProjectAttributeData = ReturnType<typeof useEditProjectAttribute>;
