import { useAppActions } from "../../AppActionsProvider";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useAttachedDocument } from "../Documents/useDocument";
import { useSpace } from "../Spaces/SpaceContext"
import { Task } from "./typings";

export const useTaskEdit = (taskId: string) => {
  const { spaceId } = useSpace();
  const actions = useAppActions();
  const task = useCrudItem<Task>(`/api/s/${spaceId}/task/${taskId}`, {
    defaultValue: { _id: "", project_id: "", title: "" },
    noLoad: !taskId || !spaceId,
    resetChangesOnReload: true,
  });

  const description = useAttachedDocument("task", taskId);

  const hasChanges = task.hasChanges || description.hasChanges;

  const save = async (extraChanges?: Partial<Task>) => {
    if(task.hasChanges || (extraChanges && Object.keys(extraChanges).length)) {
      await task.save(extraChanges).then(t => {
        if(t) {
          actions.signalTaskUpdated(t);
        }
        return t;
      });
    }
    if(description.hasChanges) {
      await description.save();
    }
  };

  const resolve = (x: "yes" | "no" | "toggle") => {
    save({ resolve: x === "toggle" ? task.data.resolved_at ? "no" : "yes" : x });
  }

  return {
    task,
    description,
    hasChanges,
    save,
    resolve,
  }
}
