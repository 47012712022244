import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Project } from './useProjectsList';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useFormats } from '../../toolympus/components/schemed';

const StatusDisplay = styled(Typography)`
  cursor: pointer;
  user-select: none;
`;
StatusDisplay.defaultProps = { role: "button", variant: "caption", color: "textSecondary" };

export const StatusIcon = {
  finished: "✅",
  later: "🕔",
  current: "▶️",
  return: "↩️",
}

const StatusLabel = (props: {project: Project}) => {
  const p = props.project;
  const formats = useFormats();

  if(p.is_finished) {
    return <>
      <span>{StatusIcon.finished} Завершен</span>
      <span className="secondary"> {formats.formatDate(p.finished_at)}</span>
    </>
  } else if(p.is_later) {
    return <span>{StatusIcon.later} Отложен</span>
  } else {
    return <span>{StatusIcon.current} Активный</span>
  }
}

interface Props {
  project: Project;
  update: (c: Partial<Project>) => void;
}

export const ProjectStatus = (props: Props) => {
  const { project, update } = props;
  const [menuAnchor,setMenuAnchor] = useState<any>(null);
  
  return (
    <>
      <StatusDisplay onClick={e => setMenuAnchor(e.target)}>
        <StatusLabel project={project} />
      </StatusDisplay>
      
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)} keepMounted>
        {!project.is_finished && <MenuItem onClick={() => { update({ is_finished: true }); setMenuAnchor(null); }}>
          {StatusIcon.finished} Завершить
        </MenuItem>}
        {!project.is_later && !project.is_finished && <MenuItem onClick={() => { update({ is_later: true }); setMenuAnchor(null); }}>
          {StatusIcon.later} Отложить
        </MenuItem>}
        {!!project.is_finished && <MenuItem onClick={() => { update({ is_finished: false }); setMenuAnchor(null); }}>
          {StatusIcon.return} Отменить завершение
        </MenuItem>}
        {!!project.is_later && !project.is_finished && <MenuItem onClick={() => { update({ is_later: false }); setMenuAnchor(null); }}>
          {StatusIcon.return} Вернуть из отложенных
        </MenuItem>}
      </Menu>
    </>
  );
}
