import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTaskEdit } from './useTaskEdit';
import { SpellbookEditor, DocumentTitle, CurrentDocumentProvider } from '../Documents';
import { Buttons, LoadingIndicator, useCopyText, useWindowHotkey } from '../../toolympus/components/primitives';
import { Blip } from '../Common/PageComponents';
import { ThemeSettings } from '../../theme';
import { Check, Link } from '@mui/icons-material';
import isHotkey from 'is-hotkey';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';

export const DialogTitleX = styled(DialogTitle)<{ isResolved?: boolean }>`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 6px;
  background: ${props => props.isResolved ? ThemeSettings.colors.backgroundDim : "transparent"};

  & input {
    width: 100%;
  }

  line-height: 1.2;
  
`;

const ResolvedButton = styled.button<{ isVisible?: boolean }>`
  background: ${ThemeSettings.colors.semantic.resolved};
  color: #ffffff;
  padding: 3px 6px 1px;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 8px;

  opacity: ${props => props.isVisible ? 1 : 0};

  &:hover {
    opacity: ${props => props.isVisible ? 0.25 : 0.5};
  }
`;

interface Props {
  taskId: string | null;
  close: () => void;
}

export const TaskEditPopup = (props: Props) => {
  const { task, description, hasChanges, save, resolve } = useTaskEdit(props.taskId || "");

  useWindowHotkey("alt+s", () => { if(hasChanges) { save() }});
  useWindowHotkey("alt+r", () => { resolve("toggle"); });

  const copyText = useCopyText();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if(isHotkey("e", e) &&
        !(!e.target || (e.target as any).tagName === "INPUT" || (e.target as any ).role === "textbox")) {
        const title = document.querySelector(".task-title");
        if(title && (title as any).click) {
          (title as any).click();
          e.preventDefault();
        }
      }
    }
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);

  return (
    <Dialog
      open={!!props.taskId}
      maxWidth="md"
      fullWidth
      onClose={() => props.close()}>
      <DialogTitleX isResolved={!!task.data.resolved_at}>
        {task.data._id === props.taskId &&
          <DocumentTitle
            value={task.data.title}
            update={v => task.update({ title: v })}
            spanClassName="task-title"
            />}

        <Buttons gap="small">
          {task.isLoading && <LoadingIndicator sizeVariant="s" />}
          <ResolvedButton
            tabIndex={-1}
            isVisible={!!task.data.resolved_at}
            onClick={() => resolve("toggle")}>
            <Check />
          </ResolvedButton>
          {!task.isLoading && <StrippedIconButton onClick={() => copyText(window.location.href)}>
            <Link />
          </StrippedIconButton>}
          {hasChanges && <Blip onClick={() => save()} />}
        </Buttons>
      </DialogTitleX>

      <DialogContent>
        {description.isLoading && <LoadingIndicator sizeVariant="s" />}

        <CurrentDocumentProvider document={description.data}>
          {description.data._id && description.data.attached_to_id === props.taskId &&
            <SpellbookEditor
              content={description.data.content}
              update={v => description.update({ content: v })}
              />}
        </CurrentDocumentProvider>
      </DialogContent>

      <DialogActions>
        {hasChanges && <Button size="small" color="primary" variant="contained" onClick={() => save().then(() => props.close())}>сохранить</Button>}
      </DialogActions>
    </Dialog>
  );
}
