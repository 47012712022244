import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { useNewItem } from '../../toolympus/api/useNewItem';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { TextField } from '@mui/material';
import { SpaceSettingsData, useSpaceSettings } from './useSpaceSettings';

export interface Space {
  _id: string;
  title: string;
}

interface ISpaceContext {
  spaceId: string | null;
  available: Space[];
  isLoadingAvailable: boolean;
  select: (sid: string) => void;
  settings: SpaceSettingsData;
}

const SpaceContext = React.createContext<ISpaceContext>({
  spaceId: null,
  available: [],
  isLoadingAvailable: true,
  select: () => {},
  settings: {
    isLoading: false,
    settings: {},
    update: x => Promise.resolve(x),
    hasChanges: false,
    reload: () => Promise.resolve({}),
  }
})

export const useSpace = () => useContext(SpaceContext);

interface Props {
  
}

const SpaceLSKey = "_tlsb_spaceid";

export const SpaceContextProvider = (props: PropsWithChildren<Props>) => {
  const available = useLoadedData<Space[]>("/api/space", []);
  const [spaceId,setSpaceIdX] = useState<string | null>(null);
  const setSpaceId = (sid: string | null) => {
    setSpaceIdX(sid);
    if(sid) {
      localStorage.setItem(SpaceLSKey, sid);
    } else {
      localStorage.removeItem(SpaceLSKey);
    }
  }
  
  const newSpace = useNewItem<Partial<Space>, Space>("/api/space", { title: "" }, {
  });

  const settings = useSpaceSettings(spaceId || "");

  const ctx: ISpaceContext = useMemo(() => {
    return {
      spaceId: spaceId,
      available: available.data,
      isLoadingAvailable: available.isLoading,
      select: sid => {
        if(available.data.find(s => s._id === sid)) {
          setSpaceId(sid);
        }
      },
      settings: settings,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, available.data, available.isLoading, settings.settings, settings.isLoading]);

  useEffect(() => {
    if(!spaceId && !available.isLoading) {
      if(available.data.length) {
        const fromLS = localStorage.getItem(SpaceLSKey);
        const space = (fromLS ? available.data.find(s => s._id === fromLS) : null) || available.data[0];
        setSpaceId(space._id);
      } else {
        const timeout = setTimeout(() => newSpace.startEditing({ title: "" }), 300);
        return () => clearTimeout(timeout);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available.data, available.isLoading, spaceId])

  return (
    <SpaceContext.Provider value={ctx}>
      {props.children}

      <SimpleDialog
        isOpen={newSpace.isEditing}
        close={() => newSpace.cancel()}
        dialogTitle="Создать пространство"
        saveLabel="Создать"
        noFullscreen
        save={() => {
          if(newSpace.item?.title) {
            newSpace.save()
              .then(space => {
                setSpaceId(space._id);
                newSpace.cancel();
              })
          }
        }}>

          {!!newSpace.item &&
            <FormGrid columns="1fr" noMargin>
              <TextField
                label="Название"
                value={newSpace.item.title || ""}
                onChange={e => newSpace.update({ title: e.target.value })}
                />
            </FormGrid>}

      </SimpleDialog>
    </SpaceContext.Provider>
  );
}
