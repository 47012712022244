import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { TaskEditPopup } from './components/Tasks/TaskEditPopup';
import { Task } from './components/Tasks/typings';
import { SearchPalette, useSearchPalette } from './components/Common/SearchPalette';
import { useDialogState, useWindowHotkey } from './toolympus/components/primitives';
import { DocumentsContextProvider } from './components/Documents';
import { DocumentEditPopup } from './components/Documents/DocumentEditPopup';
import { useHistory } from 'react-router-dom';

type UpdatedHandler<T> = (t: T) => void;


interface IAppActionsContext {
  openTask: (taskId: string) => void;
  openDocument: (documentId: string) => void;
  signalTaskUpdated: (t: Task) => void;
  registerTaskUpdatedHandler: (handler: UpdatedHandler<Task>) => void;
  unregisterTaskUpdatedHandler: (handler: UpdatedHandler<Task>) => void;
  openSearchPalette: () => void;
}


const AppActionsContext = React.createContext<IAppActionsContext>({
  openTask: () => {},
  openDocument: () => {},
  signalTaskUpdated: (t: Task) => {},
  registerTaskUpdatedHandler: (handler: UpdatedHandler<Task>) => {},
  unregisterTaskUpdatedHandler: (handler: UpdatedHandler<Task>) => {},
  openSearchPalette: () => {},
})

export const useAppActions = () => useContext(AppActionsContext);

interface Props {
  
}

const TaskIdQueryParam = "t";

export const AppActionsProvider = (props: PropsWithChildren<Props>) => {
  const [taskId,setTaskIdX] = useState<string | null>(null);
  const [documentId,setDocumentId] = useState<string | null>(null);

  const taskUpdatedHandlers = useRef<UpdatedHandler<Task>[]>([]);

  const searchPalette = useSearchPalette();
  const searchPaletteStatus = useDialogState();
  const history = useHistory();

  useWindowHotkey("alt+q", () => { searchPaletteStatus.open() });

  
  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const tid = query.get(TaskIdQueryParam);
    if(tid) {
      setTaskId(tid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTaskId = (tid: string | null) => {
    const urlp = new URLSearchParams(history.location.search);
    if(tid) {
      urlp.set(TaskIdQueryParam, tid);
    } else {
      urlp.delete(TaskIdQueryParam);
    }
    history.replace({ pathname: history.location.pathname, search: `?${urlp.toString()}` });
    setTaskIdX(tid);
  }


  const ctx: IAppActionsContext = {
    openTask: tid => setTaskId(tid),
    openDocument: id => setDocumentId(id),
    registerTaskUpdatedHandler: h => {
      taskUpdatedHandlers.current.push(h);
    },
    unregisterTaskUpdatedHandler: h => {
      taskUpdatedHandlers.current = taskUpdatedHandlers.current.filter(hx => hx !== h);
    },
    signalTaskUpdated: t => {
      taskUpdatedHandlers.current.forEach(handler => handler(t));
    },
    openSearchPalette: () => { searchPaletteStatus.open() },
  }


  return (
    <AppActionsContext.Provider value={ctx}>
      <DocumentsContextProvider>
        {props.children}

        <TaskEditPopup
          taskId={taskId}
          close={() => setTaskId(null)}
          />

        <DocumentEditPopup
          documentId={documentId}
          close={() => setDocumentId(null)}
          />

        <SearchPalette
          data={searchPalette}
          isOpen={searchPaletteStatus.isOpen}
          close={searchPaletteStatus.close}
          />
      </DocumentsContextProvider>
    </AppActionsContext.Provider>
  );
}
