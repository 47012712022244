import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { SpellbookEditor, DocumentTitle, CurrentDocumentProvider, useDocument } from '../Documents';
import { Buttons, LoadingIndicator, useWindowHotkey } from '../../toolympus/components/primitives';
import { Blip } from '../Common/PageComponents';
import isHotkey from 'is-hotkey';
import { DialogTitleX } from '../Tasks/TaskEditPopup';


interface Props {
  documentId: string | null;
  close: () => void;
}

export const DocumentEditPopup = (props: Props) => {
  const { data, update, hasChanges, isLoading, save } = useDocument(props.documentId || "");

  useWindowHotkey("alt+s", () => { if(hasChanges) { save() }});

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if(isHotkey("e", e) &&
        !(!e.target || (e.target as any).tagName === "INPUT" || (e.target as any ).role === "textbox")) {
        const title = document.querySelector(".doc-title");
        if(title && (title as any).click) {
          (title as any).click();
          e.preventDefault();
        }
      }
    }
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);

  return (
    <Dialog
      open={!!props.documentId}
      maxWidth="md"
      fullWidth
      onClose={() => props.close()}>
      <DialogTitleX>
        {data._id === props.documentId &&
          <DocumentTitle
            value={data.title}
            update={v => update({ title: v })}
            spanClassName="doc-title"
            />}

        <Buttons gap="small">
          {isLoading && <LoadingIndicator sizeVariant="s" />}
          {hasChanges && <Blip onClick={() => save()} />}
        </Buttons>
      </DialogTitleX>

      <DialogContent>
        {isLoading && <LoadingIndicator sizeVariant="s" />}

        <CurrentDocumentProvider document={data}>
          {data._id && data._id === props.documentId &&
            <SpellbookEditor
              content={data.content}
              update={v => update({ content: v })}
              />}
        </CurrentDocumentProvider>
      </DialogContent>

      <DialogActions>
        {hasChanges && <Button size="small" color="primary" variant="contained" onClick={() => save().then(() => props.close())}>сохранить</Button>}
      </DialogActions>
    </Dialog>
  );
}
