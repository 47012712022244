import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { SearchPaletteData } from './useSearchPalette';
import { List, ListItem, ListItemText, TextField } from '@mui/material';
import isHotkey from 'is-hotkey';


const Search = styled.div`
    display: flex;
    flex-flow: row;
    width: 100%;

    & > :first-child {
        flex: 1 0 auto;
    }
`;

const Wrapper = styled.div`
    position: fixed;
    top: 5vh;
    left: 20%;
    width: 60%;
    height: auto;
    padding: 0 1rem 0.5rem;
    box-shadow: 0 0 10px -5px #33333380;
    z-index: 2000;
    background: ${props => props.theme.palette.background.default};

    ${props => props.theme.breakpoints.down("sm")} {
      left: 5%;
      width: 90%;
      box-sizing: border-box;
    }
`;

interface Props {
  data: SearchPaletteData;
  isOpen: boolean;
  close: () => void;
}

export const SearchPalette = (props: Props) => {
  const palette = props.data;

  useEffect(() => {
    if(!props.isOpen) {
      palette.setSearch("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  if(!props.isOpen) {
      return null;
  }
  
  return (
    <Wrapper>
        <Search>
            <TextField
                autoFocus
                value={palette.search}
                onChange={e => palette.setSearch(e.target.value)}
                onBlur={() => { setTimeout(() => props.close(), 300)}}
                onKeyDown={e => {
                    if(isHotkey("Enter", e)) {
                        palette.invokeActiveTarget();
                        props.close();
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    else if(isHotkey("Esc", e)) {
                        props.close();
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    else if(isHotkey("down", e)) {
                        palette.nextTarget();
                        e.stopPropagation();
                    }
                    else if(isHotkey("up", e)) {
                        palette.prevTarget()
                        e.stopPropagation();
                    }
                }}
                />
        </Search>
        {palette.searchResults.length > 0 && <List dense>
            {palette.searchResults.map((item,idx) => (
                <ListItem
                    key={item.url}
                    button
                    selected={idx === palette.activeTargetIndex}
                    onClick={() => { palette.invokeTarget(item); props.close(); }}
                    >
                    <ListItemText
                        primary={item.title}
                        secondary={item.kind}
                        />
                </ListItem>))}
        </List>}
    </Wrapper>
  );
}
