import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';

const Editor = styled(TextField)`
    & .MuiInputBase-root {
        color: ${props => props.theme.palette.text.primary};
        font-size: inherit;
        font-weight: 400;
        /* line-height: 1.235; */
        /* letter-spacing: 0.00735em; */
        margin: 0;
    }
    
    & .MuiInputBase-root:before, & .MuiInput-underline:hover:before {
        border-bottom: none;
    }
`;

interface Props {
    value?: string;
    update: (v: string) => void;
    style?: React.CSSProperties;
    placeholder?: string;
    readOnly?: boolean;
    spanClassName?: string;
}

export const DocumentTitle = (props: Props) => {
    const { value, update, style, placeholder, readOnly } = props;
    const [isEditing, setIsEditing] = useState<boolean>(false);

    return isEditing
      ? (
        <Editor
            value={value || ""}
            onChange={e => update(e.target.value)}
            placeholder={placeholder || "..."}
            InputProps={{ readOnly: readOnly }}
            autoFocus
            style={style}
            onBlur={() => setIsEditing(false)}
            fullWidth
            />
    )
      : <span className={props.spanClassName} onClick={() => setIsEditing(true)}>{props.value || "..."}</span>;
}
