import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { Editor } from 'slate';
import { useSlateStatic } from 'slate-react';
import { DialogState, LoadingIndicator, SimpleDialog } from '../../toolympus/components/primitives';

interface Props {
    state: DialogState;
    createDocument: (editor: Editor, title: string) => Promise<any | void>;
    isSaving: boolean;
}

export const InsertNestedDocumentDialog = (props: Props) => {
    const [title, setTitle] = useState<string>("");
    const editor = useSlateStatic();

    return (
        <SimpleDialog
            {...props.state}
            dialogTitle="Создать раздел"
            
            save={() => {
                if(editor && title) {
                    props.createDocument(editor, title)
                        .then(() => {
                            setTitle("");
                            props.state.close();
                        });
                }
            }}
            saveLabel={props.isSaving ? <LoadingIndicator sizeVariant="font" /> : undefined}
            noFullscreen>
                
            <TextField
                value={title}
                onChange={e => setTitle(e.target.value)}
                fullWidth
                autoFocus
                label="Название"
                />
        </SimpleDialog>
    );
}
