import React from "react";
import { WorkOutline, Settings } from "@mui/icons-material";
import { Roles } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { ProjectsListPage } from "./components/Projects/ProjectsListPage";
import { ProjectPage } from "./components/Projects/ProjectPage";

const InnerRoutes: MenuRoute[] = [
    { 
      path: '/projects/:id',
      title: 'Example page',
      hidden: true,
      component: ProjectPage,
    },
    { 
        path: '/projects',
        title: 'Проекты',
        icon: <WorkOutline />,
        component: ProjectsListPage,
        alsoActivateForPath: (path: string) => path.startsWith('/projects')
    },
    
    { 
        path: '/system',
        title: 'System',
        hidden: true,
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                users={{ userRoles: Roles, allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, twoFactorAuthEnabled: true }}
                pages={[{
                    apiPath: "/api/page",
                    key: "all",
                    label: "All",
                }]}
                messages={{}}
            />),
    },
];

export default InnerRoutes;