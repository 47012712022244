import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { ThemeSettings } from '../../theme';
import { PseudoLink2 } from '../../toolympus/components/primitives';

const contentPaddingCss = css`
  padding-left: 16px;
  padding-right: 16px;
`;

export const PageWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  max-height: 100%;
  overflow-y: hidden;
`;

interface PanelProps {
  noBackground?: boolean;
  noVerticalPadding?: boolean;
  isMobileHidden?: boolean;
}

const panelCss = (props: PanelProps) => css`
  background-color: ${props.noBackground ? "transparent" : ThemeSettings.colors.background};
  box-shadow: ${ThemeSettings.shadows.panel} ${props.noBackground ? "transparent" : ThemeSettings.colors.shadow};
  border-radius: 4px;
  padding-top: ${props.noVerticalPadding ? 0 : 12}px;
  padding-bottom: ${props.noVerticalPadding ? 0 : 12}px;

  ${(props as any).theme.breakpoints.down("sm")} {
    display: ${props.isMobileHidden ? "none" : "block"};
  }
`;

export const PageMainColumn = styled.div<PanelProps>`
  ${contentPaddingCss}
  ${props => panelCss(props)}
  max-height: 100%;
  overflow-y: auto;

`;

export const PageSecondaryColumn = styled.div<PanelProps>`
  ${contentPaddingCss}
  ${props => panelCss(props)}
  overflow-y: auto;
`;


export const PageTitle = styled(Typography)`
  margin: 0;
  font-size: 2rem;
  user-select: none;

  ${props => props.theme.breakpoints.down("sm")} {
    font-size: 1.6rem;
  }
`;
PageTitle.defaultProps = { variant: "h2" };

export const PageTitleLine = styled.div<PanelProps & { dontGrowTitle?: boolean }>`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 12px;
  ${props => panelCss(props)}
  padding-top: 24px;
  padding-bottom: 12px;
  ${contentPaddingCss}

  & ${PageTitle} {
    ${props => props.dontGrowTitle ? "" : "flex: 1 0 auto;"}
  }

  ${props => props.theme.breakpoints.down("sm")} {
    flex-flow: column;

    & > :last-child {
      align-self: flex-end;
    }
  }
`;


export const Blip = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${ThemeSettings.colors.primary};
  display: inline-block;
`;


export const PageContent = styled.div`
  max-height: 100%;
  overflow-y: hidden;

  ${props => props.theme.breakpoints.down("sm")} {
    align-content: flex-start;
  }
`;

export const PageContent7x3 = styled(PageContent)`
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 20px;

  ${props => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
`;

export const TogglePseudolink = styled(PseudoLink2)<{ isInactive?: boolean }>`
  opacity: ${props => props.isInactive ? 0.6 : 1};
  border-color: ${props => props.isInactive ? "transparent" : "currentColor"};
`;
