import { useEffect, useMemo } from "react";
import { useAppActions } from "../../AppActionsProvider";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSpace } from "../Spaces/SpaceContext";
import { Task } from "./typings";


export const useProjectResolvedTasks = (projectId: string) => {
  const { spaceId } = useSpace();
  const actions = useAppActions();

  const data = useLoadedData<Task[]>(`/api/s/${spaceId}/task?project_id=${projectId}&resolved=only`, [], !!spaceId && !!projectId);

  useEffect(() => {
    const onTaskChanged = (task: Task) => {
      if(task.project_id === projectId) {
        data.setData(x => x.map(t => t._id === task._id ? { ...t, ...task } : t));
      }
    }
    actions.registerTaskUpdatedHandler(onTaskChanged);
    return () => actions.unregisterTaskUpdatedHandler(onTaskChanged);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  const sortedTasks = useMemo(() => {
    return data.data.sort((t1,t2) => (t1.resolved_at || "") < (t2!.resolved_at || "") ? 1 : -1);
  }, [data.data]);

  return {
    ...data,
    data: sortedTasks,
    
  }
}
