import React, { PropsWithChildren } from 'react';
import { useSpace } from './components/Spaces/SpaceContext';
import { MediaLibProvider } from './toolympus/components/medialib';

interface Props {
  
}

export const SpaceMediaLibProvider = (props: PropsWithChildren<Props>) => {
  const { spaceId } = useSpace();

  return (
    <MediaLibProvider
      apiPath="/api/media"
      spaceId={spaceId || ""}
      loadOnDemand
      protectedFiles
      getFilepath={mf => `/api/media/${mf.space_id}/${mf._id}`}>
      {props.children}
    </MediaLibProvider>
  );
}
