import { useMemo } from "react";
import { useSpace } from "../Spaces/SpaceContext"
import { ProjectFieldsKeys, ProjectFieldsSettingKey } from "./ProjectsSettingsEdit";

export interface ProjectField {
  key: string;
  title: string;
  sortorder: number;
  is_enabled: boolean;
}

export const useProjectsSettings = () => {
  const { settings } = useSpace();

  const fields = useMemo(() => {
    const projectFieldsSettings = (settings.settings || {})[ProjectFieldsSettingKey] || {};
    return ProjectFieldsKeys
      .map(k => ({ key: k, is_enabled: false, ...(projectFieldsSettings[k] || {})}) as ProjectField)
      .filter(x => x.is_enabled)
      .sort((a,b) => (a.sortorder || 0) < (b.sortorder || 0) ? -1 : 1)
  }, [settings.settings]);

  return {
    fields,
  }
}
