import React, { ReactNode } from 'react';
import { LoadingIndicator, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { useAppActions } from '../../AppActionsProvider';
import { ResolvedMarkerX, TaskItem, TaskListWrapper } from './TaskDisplay';
import { useProjectResolvedTasks } from './useProjectResolvedTasks';
import { TasksListHeader } from './ProjectTasksList';


interface Props {
  projectId: string;
  headerButtons?: ReactNode;
}

export const ProjectResolvedTasksList = (props: Props) => {
  const data = useProjectResolvedTasks(props.projectId);

  const actions = useAppActions();


  return (<>
    <TasksListHeader>
      Задачи завершенные
      {data.isLoading && <LoadingIndicator className="loader" />}

      <OccupyFreeSpace />

      {props.headerButtons}
    </TasksListHeader>

    <TaskListWrapper>
      {data.data.map(t => (
        <TaskItem
        key={t._id}
        isResolved={!!t.resolved_at}
        onClick={() => actions.openTask(t._id)}>
          {t.title}
          {!!t.resolved_at && <ResolvedMarkerX />}
        </TaskItem>
      ))}
    </TaskListWrapper>
  </>
  );
}
