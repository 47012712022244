import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { useAttachedDocument } from "../Documents/useDocument";
import { useSpace } from "../Spaces/SpaceContext";
import { Project } from "./useProjectsList";

export const useProject = (id: string) => {
  const { spaceId } = useSpace();
  const project = useCrudItem<Project>(`/api/s/${spaceId}/project/${id}`, {
    defaultValue: { project_id: "", space_priority: 0, status: "", title: "" },
    noLoad: !id || !spaceId,
  })

  const description = useAttachedDocument("project", id);

  const hasChanges = project.hasChanges || description.hasChanges;

  const save = () => {
    if(project.hasChanges) {
      project.save();
    }
    if(description.hasChanges) {
      description.save();
    }
  };

  return {
    attributes: project,
    description,
    save,
    hasChanges,
  }
}

export type ProjectData = ReturnType<typeof useProject>;
