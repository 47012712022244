import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { DocumentSB } from './useDocument';
import { useSpace } from '../Spaces/SpaceContext';
import { apiFetch } from '../../toolympus/api/core';
import { useThrottledCapture } from '../../toolympus/components/primitives';
import { toMap } from '../../toolympus/api/data';

export interface IDocumentsContext {
  ensureDocumentsTitles: (documentsIds: string[]) => void;
  documentsTitles: Record<string, string>;
  createDocument: (doc: Partial<DocumentSB>, parent: DocumentSB | null) => Promise<DocumentSB>;
}

const DocumentsContext = React.createContext<IDocumentsContext>({
  ensureDocumentsTitles: () => {},
  documentsTitles: {},
  createDocument: doc => Promise.resolve({ ...doc, _id: "" } as DocumentSB),
});

export const useDocumentsContext = () => useContext(DocumentsContext);


const CurrentDocumentContext = React.createContext<DocumentSB | null>(null);

export const CurrentDocumentProvider = (props: PropsWithChildren<{ document: DocumentSB }>) => {
  return <CurrentDocumentContext.Provider value={props.document}>
    {props.children}
  </CurrentDocumentContext.Provider>
}

export const useCurrentDocument = () => useContext(CurrentDocumentContext);

const useDocumentsContextData = (): IDocumentsContext => {
  const { spaceId } = useSpace();
  const [requestedDocumentsTitles, setRequestedDocumentsTitles] = useState<string[]>([]);
  const doRequestDocumentsTitles = useThrottledCapture(requestedDocumentsTitles, 300);
  const [documentsTitles, setDocumentsTitles] = useState<Record<string, string>>({});

  useEffect(() => {
    if(doRequestDocumentsTitles.length && spaceId) {
      apiFetch<DocumentSB[]>(`/api/s/${spaceId}/document?ids=${doRequestDocumentsTitles.join(",")}`)
        .then(docs => {
          const newTitles = toMap(docs, d => d._id, d => d.title);
          setDocumentsTitles(x => ({ ...x, ...newTitles }));
          setRequestedDocumentsTitles([]);
        })
    }
  }, [spaceId, doRequestDocumentsTitles]);

  const value: IDocumentsContext = useMemo(() => {
    const ensureDocumentsTitles = (documentsIds: string[]) => {
      setRequestedDocumentsTitles(x => [...x, ...documentsIds].filter(id => !documentsTitles[id]))
    }
  
    const createDocument = (doc: Partial<DocumentSB>, parent: DocumentSB | null) => {
      if(!spaceId) {
        throw new Error("no space");
      }

      return apiFetch<DocumentSB>(`/api/s/${spaceId}/document`, "post", { parent_document_id: parent?._id || null, ...doc })
        .then(d => {
          setDocumentsTitles(x => ({ ...x, [d._id]: d.title }))
          return d;
        });
    }

    return {
      documentsTitles,
      ensureDocumentsTitles,
      createDocument,
    }
  }, [spaceId, documentsTitles]);

  return value;
}

interface Props {
  
}

export const DocumentsContextProvider = (props: PropsWithChildren<Props>) => {
  const ctx = useDocumentsContextData();

  return (
    <DocumentsContext.Provider value={ctx}>
      {props.children}
    </DocumentsContext.Provider>
  );
}
