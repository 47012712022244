import React, { useEffect, useState } from 'react';
import { ProjectSchema, useProjectsList } from './useProjectsList';
import { PageContent, PageMainColumn, PageTitle, PageTitleLine, PageWrapper } from '../Common/PageComponents';
import { Buttons, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SimpleDialog, useWindowHotkey } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { IconButton, styled } from '@mui/material';
import { useProjectsSettings } from './useProjectsSettings';
import { Add } from '@mui/icons-material';
import { ProjectsList } from './ProjectsList';
import { ThemeSettings } from '../../theme';
import { StatusIcon } from './ProjectStatus';
import { Task } from '../Tasks/typings';
import { useAppActions } from '../../AppActionsProvider';

interface Props {
  
}

const TitleLinks = styled(Buttons)`
  font-size: ${ThemeSettings.typography.fontSizes.smaller};
`;


export const ProjectsListPage = (props: Props) => {
  const data = useProjectsList();
  useWindowHotkey("alt+n", () => {
    data.newProject.startEditing({ title: "" });
  });

  const { fields } = useProjectsSettings();

  const [showTasks, setShowTasks] = useState<boolean>(true);

  const [tab, setTab] = useState<string>("current");

  useEffect(() => {
    if(tab === "later") {
      data.laterProjects.requestLoad();
    }
    if(tab === "finished") {
      data.finishedProjects.requestLoad();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const actions = useAppActions();
  useEffect(() => {
    const onTaskChanged = (task: Task) => {
      if(data.currentTasks.data[task.project_id]) {
        const found = data.currentTasks.data[task.project_id].find(x => x._id === task._id);
        if(found) {
          data.currentTasks.setData(x => ({
            ...x,
            [task.project_id]: data.currentTasks.data[task.project_id].map(t => t._id === task._id ? { ...t, ...task } : t),
          }));
        }
      }
    }
    actions.registerTaskUpdatedHandler(onTaskChanged);
    return () => actions.unregisterTaskUpdatedHandler(onTaskChanged);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <PageTitleLine dontGrowTitle>
        <PageTitle>
        Проекты: {tab === "current" ? "текущие" : ""}{tab === "later" ? "отложенные" : ""}{tab === "finished" ? "завершенные" : ""}
        </PageTitle>
        <TitleLinks>
          {tab !== "current" && <PseudoLink2 onClick={() => setTab("current")}>{StatusIcon.current} текущие</PseudoLink2>}
          {tab !== "later" && <PseudoLink2 onClick={() => setTab("later")}>{StatusIcon.later} отложенные</PseudoLink2>}
          {tab !== "finished" && <PseudoLink2 onClick={() => setTab("finished")}>{StatusIcon.finished} завершенные</PseudoLink2>}
        </TitleLinks>


        <OccupyFreeSpace />
        {data.list.isLoading && <LoadingIndicator />}
        <IconButton size="small" color="primary" onClick={() => data.newProject.startEditing({ title: "" })}>
          <Add />
        </IconButton>
      </PageTitleLine>
      <PageContent>
        <PageMainColumn>
          {tab === "current" &&
            <ProjectsList
              data={data.list}
              editAttributes={data.editAttributes}
              fields={fields}
              currentTasks={data.currentTasks}
              reorderProjectBefore={data.reorderProjectBefore}
              setShowTasks={setShowTasks}
              showTasks={showTasks}
              />}

          {tab === "later" &&
            <ProjectsList
              data={data.laterProjects}
              editAttributes={data.editAttributes}
              fields={fields}
              reorderProjectBefore={data.reorderProjectBefore}
              />}

          {tab === "finished" &&
            <ProjectsList
              data={data.finishedProjects}
              editAttributes={data.editAttributes}
              fields={fields}
              />}
          
        </PageMainColumn>
      </PageContent>

      <SimpleDialog
        dialogTitle="Создать проект"
        saveLabel="создать"
        noFullscreen
        save={() => data.newProject.save()}
        isOpen={data.newProject.isEditing}
        close={data.newProject.cancel}
        >
          <FormGrid columns="1fr">
            <FormControlsForFields
              fields={[
                ["title", { controlProps: { autoFocus: true } }]
              ]}
              schema={ProjectSchema}
              data={data.newProject.item}
              onChange={(o,c) => data.newProject.update(c)}
              />
          </FormGrid>
      </SimpleDialog>
    </PageWrapper>
  );
}
