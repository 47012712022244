import React, { ReactNode } from 'react';
import { useProjectTasks } from './useProjectTasks';
import { Buttons, LoadingIndicator, OccupyFreeSpace, useThrottledState, useWindowHotkey } from '../../toolympus/components/primitives';
import isHotkey from 'is-hotkey';
import { Task } from './typings';
import { TaskDisplay, TaskItemCreator, TaskItemPlaceholder, TaskListWrapper } from './TaskDisplay';
import { styled, Typography } from '@mui/material';
import { ThemeSettings } from '../../theme';

export const TasksListHeader = styled(Buttons)`
  margin-bottom: 1rem;

  & a[role="button"] {
    font-size: ${ThemeSettings.typography.fontSizes.smaller};
  }
`;

interface Props {
  projectId: string;
  headerButtons?: ReactNode;
}

export const ProjectTasksList = (props: Props) => {
  const data = useProjectTasks(props.projectId);

  const startCreateNewTask = () => data.newTask.startEditing({ project_id: props.projectId, title: "" });

  useWindowHotkey("alt+t", startCreateNewTask);

  
  const [dragging,setDragging] = useThrottledState<Task | null>(null);
  const [dragTarget,setDragTarget] = useThrottledState<Task | null>(null);
  const [isCurrentPlaceholderDragTarget,setIsCurrentPlaceholderDragTarget] = useThrottledState<boolean>(false);

  return (<>

    <TasksListHeader>
      Задачи текущие
      {(data.isLoading || data.newTask.isLoading) && <LoadingIndicator className="loader" />}

      <OccupyFreeSpace />

      {props.headerButtons}
    </TasksListHeader>
  
    <TaskListWrapper>

      {data.currentTasks.map(t => (
        <TaskDisplay
          key={t._id}
          task={t}
          onDrop={tx => {
            if(dragging && t !== dragging) {
              data.reorderCurrent.reorderBefore(dragging, tx);
            }
            setDragging(null);
            setDragTarget(null);
            setIsCurrentPlaceholderDragTarget(false);
          }}
          dragTargetTask={dragTarget}
          draggedTask={dragging}
          setDragTargetTask={setDragTarget}
          setDraggedTask={setDragging}
          reorderTo={data.reorderCurrent.reorderTo}
          />
      ))}


      <TaskItemPlaceholder
        isDragTarget={isCurrentPlaceholderDragTarget}
        onDragEnter={e => { e.preventDefault(); setIsCurrentPlaceholderDragTarget(true); }}
        onDragOver={e => { e.preventDefault(); setIsCurrentPlaceholderDragTarget(true);  }}
        onDragLeave={e => { e.preventDefault(); setIsCurrentPlaceholderDragTarget(false); }}
        onDrop={() => {
          if(dragging) {
            data.reorderCurrent.reorderBefore(dragging, null);
          }
          setDragging(null);
          setDragTarget(null);
          setIsCurrentPlaceholderDragTarget(false);
        }}>
        перетащите задачу сюда, чтобы добавить в текущие
      </TaskItemPlaceholder>


      <Typography>Все</Typography>

      {data.newTask.isEditing && (
        <TaskItemCreator>
          <textarea
            autoFocus
            value={data.newTask.item?.title || ""}
            onChange={e => data.newTask.update({ title: e.target.value })}
            onKeyDown={e => {
              if(isHotkey("enter", e)) {
                e.preventDefault();
                data.newTask.save()
                  .then(() => setTimeout(() => startCreateNewTask(), 300));
              } else if(isHotkey("esc", e)) {
                e.preventDefault();
                data.newTask.cancel();
              }
            }}
            onBlur={() => {
              if(data.newTask.item?.title) {
                data.newTask.save()
                  .then(() => setTimeout(() => startCreateNewTask(), 300));
              } else {
                data.newTask.cancel();
              }
            }}
            />
        </TaskItemCreator>
      )}
      
      {data.data.map(t => (
        <TaskDisplay
          key={t._id}
          task={t}
          onDrop={tx => {
            if(dragging && t !== dragging) {
              data.reorderFull.reorderBefore(dragging, tx);
            }
            setDragging(null);
            setDragTarget(null);
            setIsCurrentPlaceholderDragTarget(false);
          }}
          dragTargetTask={dragTarget}
          draggedTask={dragging}
          setDragTargetTask={setDragTarget}
          setDraggedTask={setDragging}
          reorderTo={data.reorderFull.reorderTo}
          />
      ))}
    </TaskListWrapper>
    </>
  );
}
