import { useCrudItem } from "../../toolympus/api/useSimpleCrud"

export type SpaceSettings = Record<string, any>;

export const useSpaceSettings = (spaceId: string) => {
  const data = useCrudItem<SpaceSettings>(`/api/space/${spaceId}/settings`, {
    defaultValue: {},
    noLoad: !spaceId,
  });

  return {
    isLoading: data.isLoading,
    settings: data.data,
    update: (changes: SpaceSettings) => data.save(changes),
    hasChanges: data.hasChanges,
    reload: data.reload,
  }
}

export type SpaceSettingsData = ReturnType<typeof useSpaceSettings>;
