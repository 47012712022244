import React, { Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from './theme';
import { MenuItem } from './toolympus/components/frame';
import { Link, useHistory } from 'react-router-dom';
import { SettingsOutlined, ExitToAppOutlined, ArrowForwardIos, ArrowBackIos, Menu, SearchOutlined } from '@mui/icons-material';
import { Buttons, OccupyFreeSpace } from './toolympus/components/primitives';
import LogoSrc from './burst_logo.png';
import { useAppActions } from './AppActionsProvider';

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const MenuButtonTitle = styled.span`
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
`;

const MenuButton = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  color: ${props => props.isActive ? ThemeSettings.colors.primary : ThemeSettings.colors.textOnDark};
  opacity: ${props => props.isActive ? 1 : 0.85};

  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    opacity: 1;
  }
`;

const TopMenuSection = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  & img {
    width: auto;
    height: 80%;
    max-width: 100%;
  }
`;

const MenuLinksSection = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;  
`;

const MenuView = styled.nav<{ isOpen?: boolean }>`
  background: ${ThemeSettings.colors.text};
  color: ${ThemeSettings.colors.textOnDark};
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${ThemeSettings.zIndex.menu};
  box-shadow: ${ThemeSettings.shadows.panel} ${ThemeSettings.colors.shadow};

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 12px;  
  
  height: 100%;
  box-sizing: border-box;
  width: ${props => props.isOpen ? ThemeSettings.sizes.menu.width.expanded : ThemeSettings.sizes.menu.width.collapsed}px;
  padding: 6px 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  ${TopMenuSection} {
    
  }

  & ${MenuButtonTitle} {
    display: ${props => props.isOpen ? "initial" : "none"};
  }

  & .tail-buttons {
    flex-flow: ${props => props.isOpen ? "row-reverse" : "column"};
    justify-content: ${props => props.isOpen ? "space-between" : "flex-start"};
    width: 100%;
  }

  transition: width 0.35s ease;

  ${props => props.theme.breakpoints.down("sm")} {
    display: ${props => props.isOpen ? "flex" : "none"};
  }

`;

const MobileMenuButton = styled.button<{ isMenuOpen?: boolean }>`
  position: fixed;
  z-index: ${ThemeSettings.zIndex.menu};
  display: none;

  bottom: 12px;
  left: 12px;
  height: auto;
  padding: 3px 4px;
  
  color: ${ThemeSettings.colors.text};
  background: transparent;
  box-shadow: ${ThemeSettings.shadows.panel} ${ThemeSettings.colors.shadow};
  outline: none;
  border: none;
  border-radius: 8px;

  & > svg {
    display: block;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    display: ${props => props.isMenuOpen ? "none" : "block"};
  }
`;

const MobileMenuShade = styled.div<{ isVisible?: boolean }>`
  display: none;
  background-color: ${ThemeSettings.colors.text};
  opacity: 0.5;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;


  z-index: ${ThemeSettings.zIndex.menu - 1};
  ${props => props.theme.breakpoints.down("sm")} {
    display: ${props => props.isVisible ? "block" : "none"};
  }
`;

interface MenuProps {
  menuItems: MenuItem[];
  logout: () => void;
  openSettings: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const isMenuItemActive = (currentPath: string, item: MenuItem) => {
  const isSelected = currentPath.replace(/\//g, '') === item.path.replace(/\//g, '');
  const alsoActivated = item.alsoActivateForPath !== undefined && item.alsoActivateForPath(currentPath);
  return isSelected || alsoActivated;
}




export const AppMenu = (props: MenuProps) => {
  const { menuItems, logout, openSettings, isOpen, setIsOpen } = props;

  const history = useHistory();
  const actions = useAppActions();

  return (<>
    <MobileMenuShade isVisible={isOpen} onClick={() => setIsOpen(false)} />
    <MenuView isOpen={isOpen}>
      
      <TopMenuSection>
        <img src={LogoSrc} alt="Spellbook" />
      </TopMenuSection>

      <MenuLinksSection>
        {menuItems.map(item => (
          <UnstyledLink to={item.path} key={item.path}>
            <MenuButton title={item.title} isActive={isMenuItemActive(history.location.pathname, item)}>
              {item.icon} <MenuButtonTitle>{item.title}</MenuButtonTitle>
            </MenuButton>
          </UnstyledLink>
        ))}

        <MenuButton onClick={() => actions.openSearchPalette()}>
          <SearchOutlined /> <MenuButtonTitle>Поиск</MenuButtonTitle>
        </MenuButton>
        <MenuButton onClick={() => openSettings()}>
          <SettingsOutlined /> <MenuButtonTitle>Настройки</MenuButtonTitle>
        </MenuButton>
        
      </MenuLinksSection>

      <OccupyFreeSpace />

      <Buttons className="tail-buttons">
        <MenuButton onClick={() => setIsOpen(x => !x)}>
          {isOpen ? <ArrowBackIos /> : <ArrowForwardIos />}
        </MenuButton>

        <MenuButton onClick={() => logout()}>
          <ExitToAppOutlined />
        </MenuButton>
      </Buttons>
    </MenuView>

    <MobileMenuButton isMenuOpen={isOpen} onClick={() => setIsOpen(true)}>
      <Menu />
    </MobileMenuButton>
  </>)
}
