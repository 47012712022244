import { useState } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Schema } from "../../toolympus/hooks/useSchema";
import { useSpace } from "../Spaces/SpaceContext";
import { Task } from "../Tasks/typings";
import { useEditProjectAttribute } from "./useEditProjectAttribute";

export interface Project {
  project_id: string;
  title: string;
  status: string;
  tags?: string[];
  space_priority: number;
  is_later?: boolean;
  is_finished?: boolean;
  finished_at?: string;
}

export const ProjectSchema: Schema = {
  title: { label: "Название" },
}

const useLoadedWithRequest = <T,>(apiPath: string, dflt: T) => {
  const [isLoadRequested, setIsLoadRequested] = useState<boolean>(false);
  const data = useLoadedData<T>(apiPath, dflt, !!apiFetch && isLoadRequested);

  return {
    ...data,
    requestLoad: () => setIsLoadRequested(true),
  }
}

export const useProjectsList = () => {
  const { spaceId } = useSpace();
  const apiPathBase = spaceId ? `/api/s/${spaceId}/project` : "";
  const data = useLoadedData<Project[]>(apiPathBase, [], !!apiPathBase);

  const currentTasks = useLoadedData<Record<string, Task[]>>(`/api/s/${spaceId}/tasklist/current-tasks-for-projects`, {}, !!spaceId);

  const laterProjects = useLoadedWithRequest<Project[]>(`${apiPathBase}?later=t`, []);
  const finishedProjects = useLoadedWithRequest<Project[]>(`${apiPathBase}?finished=t&order-by=finished_at:desc`, []);

  const newProject = useEditItem2<Partial<Project>>({
    save: (item: Partial<Project>) => {
      const project = { ...item, space_priority: Math.min(1, ...data.data.map(p => p.space_priority)) -10 };
      return apiFetch<Project>(apiPathBase, "post", project)
        .then(created => {
          data.reload();
          return created;
        })
    }
  });

  const updateProject = (projectId: string, changes: Partial<Project>) => {
    if(!projectId || !spaceId) {
      return Promise.resolve({ project_id: projectId} as Project);
    } 
    return apiFetch<Project>(`/api/s/${spaceId}/project/${projectId}`, "put", changes)
      .then(updated => {
        data.setData(projects => projects.map(p => p.project_id === updated.project_id ? { ...p, ...updated } : p));
        return updated;
      });
  }

  const reorderProjectBefore = (projectId: string, beforeProjectId: string) => {
    const reordered = data.data.find(p => p.project_id === projectId);
    const beforeProject = data.data.find(p => p.project_id === beforeProjectId);
    if(!!spaceId && reordered && beforeProject) {
      const beforeIdx = data.data.indexOf(beforeProject);
      const beforePriority = beforeProject.space_priority;
      let newPriority = beforePriority - 10;
      if(beforeIdx !== 0) {
        const afterIdx = beforeIdx - 1;
        const afterProject = data.data[afterIdx];
        const afterPriority = afterProject.space_priority;
        newPriority = 0.5*(beforePriority - afterPriority) + afterPriority;
      }
      apiFetch<Project>(`/api/s/${spaceId}/project/${projectId}`, "put", { space_priority: newPriority })
        .then(() => data.reload());
    }
  }

  const editAttributes = useEditProjectAttribute(updateProject);

  return {
    list: data,
    newProject,
    updateProject,
    editAttributes,
    reorderProjectBefore,
    currentTasks,
    
    laterProjects,
    finishedProjects,
  }
}

export type ProjectsListData = ReturnType<typeof useProjectsList>;
