import { useState } from "react";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSpace } from "../Spaces/SpaceContext";
import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";

export interface DocumentSB {
  _id: string;
  title: string;
  content?: any;
  parent_document_id?: string;
  attached_to_kind?: string;
  attached_to_id?: string;
}

export const useAttachedDocument = (forKind: string, forId: string) => {
  const { spaceId } = useSpace();
  const data = useLoadedData<DocumentSB>(
    `/api/s/${spaceId}/document/for/${forKind}/${forId}`,
    { _id: "", title: "" },
    !!spaceId && !!forKind && !!forId);

  const [changes, setChanges] = useState<Partial<DocumentSB>>({});
  const update = (changes: Partial<DocumentSB>) => {
    data.setData(x => ({ ...x, ...changes }));
    setChanges(x => ({ ...x, ...changes }));
  }

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const save = () => {
    if(Object.keys(changes).length) {
      setIsSaving(true);
      return apiFetch<DocumentSB>(`/api/s/${spaceId}/document/${data.data._id}`, "put", changes)
        .then(d => {
          data.setData(d);
          setIsSaving(false);
          setChanges({});
          return d;
        })
        .catch(e => {
          setIsSaving(false);
          throw e;
        })
    }
  }

  return {
    ...data,
    update,
    save,
    isSaving,
    hasChanges: !!Object.keys(changes).length,
  }
}


export const useDocument = (id: string) => {
  const { spaceId } = useSpace();
  const data = useCrudItem<DocumentSB>(`/api/s/${spaceId}/document/${id}`, {
    noLoad: !id || !spaceId,
    defaultValue: { title: "...", _id: "", },
  })
  

  
  const save = (extraChanges?: Partial<DocumentSB>) => {
    return data.save(extraChanges);
  }

  return {
    ...data,
    save,
  }
}
