import React, { useEffect } from 'react';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';
import { useSpace } from '../Spaces/SpaceContext';
import { useEditItem2 } from '../../toolympus/api/useNewItem';
import { Button, Typography } from '@mui/material';
import { Buttons, FormGrid, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props {
  
}

export const ProjectFieldsSettingKey = "_sb_project_fields";
export const ProjectFieldsKeys = [
  "custom_text1",
  "custom_text2",
  "custom_text3",
  "custom_text4",
  "custom_text5",
];

const ProjectFieldSchema: Schema = {
  is_enabled: { type: FieldType.bool, label: "Включено" },
  title: { label: "Название" },
  sortorder: { type: FieldType.number, label: "⬆️" },
}


export const ProjectsSettingsEdit = (props: Props) => {
  const { settings } = useSpace();
  const fieldsSettings = useEditItem2<Record<string, any>>({
    save: (item) => {
      return settings.update({ [ProjectFieldsSettingKey]: item })
        .then(x => x[ProjectFieldsSettingKey]);
    },
    dontResetOnSave: true,
  });

  useEffect(() => {
    fieldsSettings.startEditing(settings.settings[ProjectFieldsSettingKey] || {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.settings]);


  return (
    <>
      <Typography variant="h6">Атрибуты</Typography>
      {!!fieldsSettings.item && ProjectFieldsKeys.map(k => {
        const field = (fieldsSettings.item || {})[k] || {};
        return (
          <FormGrid key={k} columns="2fr 4fr 1fr" alignItems="flex-end">
            <FormControlsForFields
              data={field}
              onChange={(o,c) => fieldsSettings.update({ [k]: { ...field, ...c }})}
              schema={ProjectFieldSchema}
              fields={[
                ["is_enabled"],
                field.is_enabled ? ["title"] : null,
                field.is_enabled ? ["sortorder"] : null,
              ]}
              />
          </FormGrid>
      )})}

      {fieldsSettings.hasChanges &&
        <Buttons>
          <OccupyFreeSpace />
          <Button size="small" color="primary" variant="contained" onClick={() => fieldsSettings.save()}>сохранить</Button>
        </Buttons>}
    </>
  );
}
