import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { theme } from './theme';
import { GlobalStyles } from './components/Common/GlobalStyles';

interface Props {
    children?: ReactNode;
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="ru" defaultLocale="ru">
                    <FormatsProvider formats={{ date: "DD.MM.yyyy"}}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                          <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                            <GlobalStyles />
                            {props.children}
                          </DatesLocalizationProvider>
                        </SnackbarProvider>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
