import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useProject } from './useProject';
import { Blip, PageContent7x3, PageMainColumn, PageSecondaryColumn, PageTitle, PageTitleLine, PageWrapper, TogglePseudolink } from '../Common/PageComponents';
import { Buttons, LoadingIndicator, PseudoLink2, useSaveable } from '../../toolympus/components/primitives';
import { SpellbookEditor, DocumentTitle, CurrentDocumentProvider } from '../Documents';
import { ProjectTasksList } from '../Tasks/ProjectTasksList';
import { ProjectResolvedTasksList } from '../Tasks/ProjectResolvedTasksList';
import { ProjectStatus } from './ProjectStatus';

interface Props {
  
}

const MobileTabToggles = styled(Buttons)`
  display: none;

  ${props => props.theme.breakpoints.down("sm")} {
    display: flex;
    flex-flow: row;
    align-self: flex-start;
  }
`;


export const ProjectPage = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const data = useProject(id);
  const { attributes, description } = data;

  const [whichTaskList,setWhichTaskList] = useState<"current" | "resolved">("current");

  const [mobileTab, setMobileTab] = useState<string>("description");

  useSaveable(data);

  return (
    <PageWrapper>
      <PageTitleLine>
        <PageTitle>
          <DocumentTitle
            value={attributes.data.title}
            update={v => attributes.update({ title: v })}
            />
        </PageTitle>
        {(attributes.isLoading || description.isLoading || description.isSaving) && <LoadingIndicator />}
        {data.hasChanges && <Blip onClick={() => data.save()} />}
        <ProjectStatus
          project={data.attributes.data}
          update={c => data.attributes.save(c)}
          />
      </PageTitleLine>


      <PageContent7x3>
        <MobileTabToggles>
          <TogglePseudolink border isInactive={mobileTab !== "description"} onClick={() => setMobileTab("description")}>описание</TogglePseudolink>
          <TogglePseudolink border isInactive={mobileTab !== "tasks"} onClick={() => setMobileTab("tasks")}>задачи</TogglePseudolink>
        </MobileTabToggles>

        <PageMainColumn isMobileHidden={mobileTab !== "description"}>
          <CurrentDocumentProvider document={data.description.data}>
            {description.data._id &&
              <SpellbookEditor
                key={description.data._id}
                content={description.data.content}
                update={v => description.update({ content: v })}
                />}
          </CurrentDocumentProvider>
        </PageMainColumn>

        <PageSecondaryColumn noBackground isMobileHidden={mobileTab !== "tasks"}>          
          {whichTaskList === "current" &&
            <ProjectTasksList
              projectId={attributes.data.project_id}
              headerButtons={
                <PseudoLink2 onClick={() => setWhichTaskList("resolved")}>завершенные</PseudoLink2>
              }
              />}

          {whichTaskList === "resolved" &&
            <ProjectResolvedTasksList
              projectId={attributes.data.project_id}
              headerButtons={
                <PseudoLink2 onClick={() => setWhichTaskList("current")}>текущие</PseudoLink2>
              }
              />}
        </PageSecondaryColumn>
      </PageContent7x3>
    </PageWrapper>
  );
}
