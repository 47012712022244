import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useSpace } from "../../Spaces/SpaceContext";
import { useThrottledCapture } from "../../../toolympus/components/primitives";
import { useHistory } from "react-router-dom";

interface SearchResultData {
  project_id: string;
  title: string;
}

export interface SearchTarget {
  title: ReactNode;
  kind: ReactNode;
  url: string;
}

const PredefinedSearchResults = [
  {
    title: "Проекты",
    kind: "Страница",
    url: "/projects",
    searchKey: "проекты projects",
  }
]

export const useSearchPalette = () => {
  const { spaceId } = useSpace();
  const [search, setSearch] = useState<string>("");
  const searchApplied = useThrottledCapture<string>(search, 500);
  const projects = useLoadedData<SearchResultData[]>(`/api/s/${spaceId}/project?any-status=t&search=${searchApplied.trim()}`, [], !!spaceId && !!searchApplied.trim());

  const history = useHistory();

  const searchResults: SearchTarget[] = useMemo(() => {
    if(searchApplied.trim().length) {
      const fromPredefined = PredefinedSearchResults.filter(item => item.searchKey.includes(searchApplied.toLowerCase()));
      return [
        ...fromPredefined,
        ...projects.data.map(p => ({ title: p.title, kind: "Проект", url: `/projects/${p.project_id}` })),
      ]
    } else {
      return PredefinedSearchResults;
    }
  }, [searchApplied, projects.data]);

  const [activeTargetIndex, setActiveTargetIndex] = useState<number>(0);
  const activeTarget = searchResults[activeTargetIndex];

  useEffect(() => {
      setActiveTargetIndex(0);
  }, [searchApplied]);


  const invokeTarget = (r: SearchTarget) => {
    history.push(r.url);
  }

  return {
    search,
    setSearch,
    searchResults,
    
    activeTarget,
    activeTargetIndex,

    invokeTarget,
    nextTarget: () => setActiveTargetIndex(x => x + 1 >= searchResults.length ? 0 : x + 1),
    prevTarget: () => setActiveTargetIndex(x => x - 1 < 0 ? Math.max(0, searchResults.length - 1) : x - 1),

    invokeActiveTarget: () => invokeTarget(activeTarget),
  }
  
}

export type SearchPaletteData = ReturnType<typeof useSearchPalette>;
